// @import '../styles/variables.scss';
// @import '../styles/typography.scss';
// @import '../styles/layout.scss';
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;


%h1 {
  /* PN Title 1 Bold */
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.38px;
}

%h1-semibold {
  /* PN Title 1 Semibold */
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.326451px;
}

%headline {
  /* PN Headline Regular */

  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  // letter-spacing: -0.32px;
}

%h2-semibold {
  /* PN Title 2 Semibold */
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

%text {
  /* PN Text Regular */

  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  // letter-spacing: -0.24px;
}

.projects {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: var(--main-spacing);
  padding-bottom: var(--main-spacing);

  nav {
    margin-top: 1rem;
  }
  
  p {
    @extend %headline;
    margin: 0;
    margin-top: var(--double-spacing);
    white-space: pre-line;
    text-align: justify;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  .slogan {
    @extend %h1;
    color: var(--primary);
    padding-left: 65px;
    border-left: 3px solid var(--primary);
    margin-top: var(--double-spacing);
  }

  .icon-info {
    font-size: 16px;
    margin-right: 10px;
    position: relative;
    top: 0px
  }

  h2 {
    @extend %h1-semibold;
    // margin-top:var(--main-spacing);
    margin-top: 1rem;
    margin-right: 23px;
    margin-bottom: 5px;
  }

  .btn {
    margin-top: var(--main-spacing);
  }

  .projects {

    &__image {
      height: 420px;
      width: 100%;
      max-width: 100%;
      ;
      object-fit: cover;
      margin-top: var(--main-spacing);
    }

    &__container {
      @media (max-width: $md) {
        padding-left: 32px;
      }

      display: flex;
      padding-left: 65px;
      border-left:3px solid var(--primary);
      // margin-top: var(--double-spacing);
    }

    &__header {
      z-index: 5;
    }

    &__items-container {
      @media (max-width:$lg) {
        grid-template-columns: minmax(0, 1fr);
      }

      display: grid;
      // grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: var(--main-spacing);
      margin-top: var(--main-spacing);
      margin-bottom: var(--double-spacing);
    }
  }

  .projects-item {
    position: relative;
    overflow: hidden;
    background-color: #F9FAFC;
    min-height: 252px;
    padding: var(--main-spacing) var(--main-spacing) var(--double-spacing) var(--main-spacing);
    border-radius: 10px;
    // box-shadow: 5px 5px 5px #180836;
    box-shadow: 5px 5px 5px gray;

    &_with-image {
      grid-row: span 2;
      padding: 0;
    }

    h2 {
      @extend %h2-semibold;
      margin: 0;
    }

    p {
      @extend %text;
      // padding-right: 72px;
      margin: 0;
      margin-top: 14px;
    }

    ul {
      @extend %text;
      // padding-right: 72px;
      margin: 0;
      margin-top: 14px;
    }

    .btn-primary {
      border-radius: 6px;
      border-color: #2d5089;
      background-color: #2d5089;
      color: #fff;
    }

    .btn-primary:hover {
      // background-color: red;
      background-color: #345c9e;
      border-color: #325998;
    }

    .projects-item {
      &__image-container {
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;

        img {
          position: absolute;
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          left: 0;
          top: 0;
        }
      }

      &__circle-bg {
        position: absolute;
        height: 336px;
        width: 336px;
        border-radius: 50%;
        background: #72A7F8;
        opacity: 0.1;

        &_left {
          left: -123px;
          top: -42px;
        }

        &_rb {
          right: -147px;
          bottom: -127px;
        }

        &_rt {
          right: 8px;
          top: -171px;
        }

      }

      &__content {
        position: relative;
        z-index: 5;
      }

      &__container {
        display: flex;
      }

      &__image {
        height: 420px;
        width: 100%;
        max-width: 100%;
        ;
        object-fit: cover;
        margin-top: var(--main-spacing);
      }

      &__icon-container {
        position: absolute;
        position: absolute;
        height: 142px;
        width: 142px;
        border-radius: 50%;
        right: -50px;
        // bottom: -50px;
        top: -50px;
        background-color: var(--primary);

        i {
          position: absolute;
          left: 46px;
          // top:46px;
          bottom: 46px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          color: white;
          margin: 0;
        }
      }

      &__title-container {
        // @media (max-width: $sm) {
        //   flex-direction: column; 
        // }
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        // justify-content: space-between;
        justify-content: flex-start;
      }

      &__logo-container {
        // @media (max-width: $sm) {
        //   justify-content: center;
        // }
        min-width: 72px;
        margin-right: 15px;
        // margin-left: -15px;
        // margin-bottom: 16px;
        // width: 298px;
        // justify-content: end;
      }

      &__logo {
        // @media (max-width: $sm) {
        //   background-position: center;
        // }
        background-size: contain;
        // width: 92px;
        // min-width: 92px;
        // max-width: 92px;
        // height: 72px;
        height: 128px;
        background-repeat: no-repeat;
        margin-top: 1rem;
        // background-position: right;
      }
    }
  }
}
/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Regular.eot');
	src: 
		url('../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway-Regular.woff') format('woff'),
		url('../fonts/Raleway-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Bold.eot');
	src: 
		url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway-Bold.woff') format('woff'),
		url('../fonts/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Light.eot');
	src: 
		url('../fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway-Light.woff') format('woff'),
		url('../fonts/Raleway-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Semibold.eot');
	src: 
		url('../fonts/Raleway-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway-Semibold.woff') format('woff'),
		url('../fonts/Raleway-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;

%h1-semibold {
    /* PN Title 1 Semibold */
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.326451px;
  }
  %text {
    /* PN Text Regular */
  
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
  
  %text-semibold {
    /* PN Text Semibold */
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

.widget-telegram {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top:var(--double-spacing);
    padding-bottom:var(--double-spacing);
    
    margin-bottom:var(--double-spacing);
    background-color: #F9FAFC;
    h2 {
      @extend %h1-semibold;
      margin-top:0;
      margin-right: 23px;
      margin-bottom: 5px;
    }
    p {
      @extend %text;
      white-space: pre-line;
      margin-top: var(--main-spacing);
      margin-bottom: 0px;
      padding-left: 32px;
    }
    .widget-telegram {
      &__header {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
      &__iframe-container {        
        @media (max-width: $lg) {
          flex-wrap: wrap;
          flex-direction: column;
        }
        margin-top: var(--main-spacing);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      &__iframe {
        @media (max-width: $lg) {
          // width:30%;
          width: 100%;
        }
        @media (max-width: $md) {
          width:100%;
        }
        @media (max-width: $sm) {
          width:100%;
        }
        border: 0px; 
        height: 500px;
        width: 30%;
        margin: 15px;
        box-shadow: 0 0 16px 3px rgba(0,0,0,.2);
        border-radius: 10px;
        box-shadow: 5px 5px 5px #180836;
        position: relative;
        z-index: 2;
      }
      &__left {
        @media (max-width: $lg) {
          margin-right: 0px;
        }
        margin-left: 0px;
      }
      &__right {
        @media (max-width: $lg) {
          margin-left: 0px;
        }
        margin-right: 0px;
      }
      &__center {
        @media (max-width: $lg) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
    
}
$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;

.main-content{
    position: relative;
}
.wave-pattern {
    z-index: 1;
    position: absolute;
    pointer-events: none;
    &__1 {
        right: 0%;
        top: -21%;
        background-position: left;
    }
    &__2 {
        right: 0px;
        top: 21%;
        background-position: left;
    }
    &__3 {
        left: 0px;
        top: 32%;
        background-position: right;
    }
    &__4 {
        left: 0px;
        right: 0px;
        width: 100%;
        bottom: -16%;
        background-position: top;
    }
    
}

.wave {
    position: relative;
    height: 1604px;
    width: 2050px;
    z-index: 1;
    pointer-events: none;
    // margin-bottom: -604px;
    &__right {
        // top: -616px;
        left: 16%;
    }
    &__left {
        right: 82%;
    }
    &__subsequent {
        margin-top: -450px;
    }
}
   
// .wave-container {
//     // position: relative;
//     // float: left;
//     position: absolute;
//     left: 0px;
//     right: 0px;
//     top: 0px;
//     bottom: 0px;
//     height: 100%;
//     min-height: 100%;
//     // width: 100%;
//     overflow: hidden;
//     pointer-events: none;
//     margin-bottom: 0;
// }
  

/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

 @import "assets/stylesheets/fonts.scss";
 @import "assets/stylesheets/bootstrap-override.scss";
 @import "assets/stylesheets/fontello-embedded.scss";


 :root {
  --danger-bg:#e637571a;
  --danger:#E63757;
  --primary:#0f2055; 
  --gray-1: #333333;
  --caption-color: #6E84A3;
  --label: #95AAC9;
  --admin-background: #F9FAFC;
  --separator-color: #E3EBF6;
  --select-hover: "red";
  --main-spacing:28px;
  --double-spacing: 56px;
}

h2 {
  z-index: 2;
}

p {
  z-index: 2;
}

.logo-minpros {
  background-image:url('./assets/images/logo_minpros.png');
}

.header-logo {
  background-image: url('./assets/images/logo_cms.svg');
}

.header-logo-en {
  background-image: url('./assets/images/logo_cms_en.svg');
}

.logo-ruzr {
  background-image: url('./assets/images/logo_ruzr.png');

}

.logo-coo {
  background-image: url('./assets/images/logo-coo-without-text.png');
}

.logo-asrfrb {
  background-image: url('./assets/images/logo_asrfrb.png');
}

.logo-arsa {
  background-image: url('./assets/images/logo_arsa.png');
}

.logo-interdom {
  background-image: url('./assets/images/logo_interdom.png');
}

.logo-ruzr-en {
  background-image: url('./assets/images/logo_ruzr_en.png');

}

.logo-asrfrb-en {
  background-image: url('./assets/images/logo_asrfrb_en.png');
}

.logo-arsa-en {
  background-image: url('./assets/images/logo_arsa_en.png');
}

.logo-interdom-en {
  background-image: url('./assets/images/logo_interdom_en.png');
}

.wave-1 {
  background-image: url('./assets/images/wave_1.svg');
}

.waves-header {
  background-image: url('./assets/images/waves-header.svg');
}

.wave-pattern-1 {
  background-image: url('./assets/images/pattern_1.svg');
  width: 954px;
  height: 1604px;
}

.wave-pattern-2 {
  background-image: url('./assets/images/pattern_2.svg');
  width: 1194px;
  height: 1454px;
}

.wave-pattern-3 {
  background-image: url('./assets/images/pattern_3.svg');
  width: 1192px;
  height: 1809px;
}

.wave-pattern-4 {
  background-image: url('./assets/images/pattern_4.svg');
  width: 1414px;
  height: 1421px;
}

.header-image {
  background-image: url('./assets/images/pattern_header.svg');
}

.App {
  
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  * {
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
  }
  max-width: 100vw;
  overflow-x: hidden;

  background-color: #ebf1ff;
  color: #180836;
}
strong.semibold {
  font-weight: 600;
}
/*
  Typography
*/
.caption-regular {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--caption-color);
  letter-spacing: -0.078px;
}

.h1-bold {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.326451px;
  color: #000000;
  margin-bottom:36px;
}
.headline { 
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #000000;
}
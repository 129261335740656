// @import '../styles/variables.scss';
// @import '../styles/typography.scss';
// @import '../styles/layout.scss';

$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;


%h1-semibold {
  /* PN Title 1 Semibold */
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.326451px;
}
%text {
  /* PN Text Regular */

  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  // letter-spacing: -0.24px;
}

%text-semibold {
  /* PN Text Semibold */
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  // letter-spacing: -0.24px;
}
.who-we-are {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top:var(--double-spacing);
  // padding-bottom:var(--double-spacing);
  // background-color: #F9FAFC;
  
  .icon-info {
    font-size:16px;
    margin-right: 10px;
    position: relative;
    top:0px
  }
  h2 {
    @extend %h1-semibold;
    margin-top:0;
    margin-right: 23px;
    margin-bottom: 5px;
  }
  p {
    @extend %text;
    white-space: pre-line;
    // padding-right: 100px;
    margin-top: var(--main-spacing);
    margin-bottom: 0px;
    text-align: justify;
  }
  .btn {
    margin-top: var(--main-spacing);
    margin-bottom: var(--main-spacing);;
  }
  .who-we-are {
    &__header {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    &__container {
      display: flex;
    }
    &__see-all {
      @extend %text-semibold;
    }
    &__video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      margin-bottom: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  } 

}
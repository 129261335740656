$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;

%text-semibold {
  /* PN Text Semibold */
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

%h1 {
  /* PN Title 1 Bold */
  font-weight: 900;
  font-size: 2.2em;
  line-height: 1em;
  letter-spacing: 0.38px;
}
%h1-semibold {
  /* PN Title 1 Semibold */
  font-weight: 600;
  font-size: 2.2em;
  line-height: 1em;
  letter-spacing: 0.326451px;
}

select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  min-height: 28px;
  margin: 0 auto;
  min-width: 140px;
  max-width: 1440px;
  z-index: 20;
  .header {
    @media (max-width: $md) {
      margin:0 20px;
    }
    &__select {
      border-style: hidden;
      background-color: #ebf1ff;
    }
    &__column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 184px;
    }
    &__top{ 
      @media (max-width: $md) {
        display: block;
      }
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__left{ 
      @media (max-width: $md) {
        width:100%;
        justify-content: space-between;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &__logo {
      @media (max-width: $md) {
        margin-right: auto;
        margin-left: auto;
      }
      flex:0 0 88px;
      width: 88px;
      height: 102px;
      display: block;
      margin-right: 22px;
      &:hover {
        text-decoration: none;
      }
    }
    &__title {
      @extend %h1;
      color: #3B4255;
      display: block;
      box-sizing: border-box;
      &:hover {
        text-decoration: none;
      }
    }
    &__mintitle {
      color: #3B4254;
      font-weight: lighter;
      font-size: .8em;
      margin-top: 1em;
      display: block;
      align-self: flex-start;
      box-sizing: border-box;
      &:hover {
        text-decoration: none;
      }
    }
    &__mobile-menu {
      position: relative;
      top:-3px;
      &.dropdown {
        .dropdown-menu {
          &::before {
            @media (max-width: $sm) {
              content: '';
              background-color: #00000020;
              left:0;
              top:0;
              right:0;
              bottom:0;
              position: fixed;
            }
          }
          @media (max-width: $sm) {
            padding-top: 84px;
            width:calc(100% - 50px)!important;
            justify-content: space-between;
            margin: 0px;
            box-shadow: none!important;
            border: 0px!important;
            position: fixed!important;
            border-radius: 0px!important;
            height: 100%!important;
            top: 0px !important;
            left:unset!important;
            right: 0!important;
            bottom: auto;
            transform: unset!important;
          }
        }  
        &.show  {
          .dropdown-menu {
            .header__mobile-menu-container {
              @media (max-width: $sm) {
                transition: all 0.5s ease-in-out;
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
    &__mobile-menu-close {
      &.dropdown-item {
        @media (max-width:$sm) {
          font-size: 16px!important;
        }
        height:50px;
        display: flex;
        text-align: right;
        justify-content: flex-end;
        background-color: var(--admin-background);
      }
    }
    &__mobile-menu-container {
      @media (max-width: $sm) {
        background-color: white;
        width:100%;
        position: absolute;
        height: 100%;
        top: 0px ;
        left:0px;
        right: 0;
        bottom: 0;
        transition: all 0.5s ease-in-out;
        transform: translateX(100%);
      }
    }
    &__mobile-menu-title {
      background-color: var(--primary);
      height: 40px;
      display: flex;
      flex-direction: row;
      padding:0 20px;
      color:white;
      align-items: center;
      .icon-chevron_squared {
        transform: rotate(-90deg);
        font-size: 12px;
        margin-right: 12px;
      }
    }
  }
  nav {
    @media (max-width: $lg) {
      margin-left: 20px;
    }
    margin-left:48px;
    position: relative;
    top:3px;
    display:flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__right-view {
    @media (min-width: $md) {
      display: flex!important;
    }
    display: none!important;
    flex-direction: row;
    align-items: center;
    .btn {
      height: 40px;
      white-space: nowrap;
    }
  }
  &__menu-item { 
    @extend %text-semibold;
    color:#0D428C;
    margin-right: 40px;
    &:hover{ 
      color:var(--primary);
      text-decoration:none;
    }
    &_current {
      color:var(--primary)
    }
    &.active {
      color:var(--primary)
    }
    .icon-dropdown {
      margin-left:6px;
    }
  }
  .dropdown {
    .dropdown-toggle{ 
      &::after {
        content:unset!important;
      }
    }
    .dropdown-menu {
      border:0;
      background: #FFFFFF;
      border: 0.5px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      inset: 10px auto auto 0px!important; 
    }
    .dropdown-item {
      @media (max-width:$sm) {
        font-size: 18px;
        color:#0D428C;
        font-weight: 600;
      }
      padding:15px 30px 15px 20px;
      font-size:15px;
      line-height:20px;
      .btn {
        white-space: break-spaces;
        width: 100%;
      }
    }
  }
}
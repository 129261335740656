$primary: #3583F7;
$danger: #E63757;
$success: #14CC7F;

@import 'bootstrap';

.App, .modal-popup {
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom:4px;
  }
  span.label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    color: #000000;
  }
  .card {
    border:0; 
  }
  .form-control, .form-control:focus {
    border-radius: 6px;
    background-color: #F7F8FA;
    border: 1px solid #E3EBF6;
    box-sizing: border-box;
    border-radius: 0px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    padding:10px 12px 10px 12px;
    min-height: 40px;
    &.is-valid {
      border-color: var(--success);
    }
    &.is-invalid {
      background-image:unset;
      border-color: var(--danger);
      background-color: var(--danger-bg);
    }
  }
  .form-label {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    color: #6E84A3;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 8px 0;
  }
  .form-check-label {
    padding-left: 8px;
  }
  .form-check-input {
    -ms-transform: scale(1.2); /* IE */
    -moz-transform: scale(1.2); /* FF */
    -webkit-transform: scale(1.2); /* Safari and Chrome */
    -o-transform: scale(1.2); /* Opera */
    transform: scale(1.2);
    padding-left: 10px;

  }
  .form-group {
    position: relative;
    margin:14px 0 12px 0;
  }
  .input-group-prepend {
    box-sizing: border-box;
    border-radius: 0px;
  }
  .input-group-text {
    background: #F7F8FA;
    position: relative;
    border-radius: 0px;
    border-color: #E3EBF6;
    border-right-color: transparent;
    border-right: 0;
    &.is-invalid {
      border-color: var(--danger);
      border-right-color: transparent;
      background-color: var(--danger-bg);
    }
    &::before {
      display: block;
      content: '';
      height:22px;
      position: absolute;
      right: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.12);;
      z-index: 10;
    }
  }
  .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
    border-left: 0;
    margin-left: 1px;
  }
  .input-group-text  {
    line-height: 1;
  }
  .btn {
    padding:0.375rem 20px;
    .icon-heart {
      font-size:16px;
      margin-right: 8px;;
      position: relative;
      top:1px
    }
  }
  .btn-primary {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
  }
  .btn-white {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
    background-color: white;
    color: var(--primary);
  }
  .btn-primary {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
    &:disabled {
      opacity:0.4;
    }
  }
  .btn-light {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
    color:var(--primary)
  }
  .btn-link {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--caption-color);
    &:hover {
      text-decoration: none;
    }
    i {
      margin-right:5px;
    }
  }
  .btn-danger {
    border-radius: 0px;
    font-family: Raleway;
    font-weight: 600;
  }
  .btn-success {
    &.btn-icon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      padding:0;
      i {
        font-size:14px;
        line-height: 14px;
      }
    }
  }
  .nav-tabs {
    border-bottom: 1px solid var(--separator-color);
    padding-left:16px;
    .nav-item {
      border:0;
      padding-left:0;
      padding-right:0;
      margin-right:32px;
      background: transpasrent;
      
      font-family: Raleway;
      font-style: normal;
      font-size: 15px;
      letter-spacing: -0.32px;
      font-weight: 600;
      color: var(--caption-color);
      &::after {
        display: block;
        content: attr(title);
        font-weight: 600;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
      position: relative;
      justify-content: center;
      &::before{ 
        transition:left 0.25s ease-in-out, width 0.25s ease-in-out;
        width:0%;
        left:50%;
        content:'';
        height:2px;
        position: absolute;
        bottom:0;
        background: var(--primary);
        
      }
      &.active, &:hover {
        // font-weight: 600;
        border:0;
        color:#000;
        background: transparent;
        &::before{ 
          width:100%;
          left:0%;
        }
      }
    }
  }
  .form-row {
    @media (min-width: 992px) {
      >:nth-child(odd ) {
        padding-right: 30px;
      }
      >:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
  .custom-file-label {
    border:0;
    color:var(--primary);
    padding-left:0;
    font-weight: 600;
    &::before {
      margin-right: 11px;
      font-family: 'fontello';
      content:'\e809';
      font-size: 17px;
    }
    &::after {
        content:unset!important;
    }
  }
  .react-daterange-picker {

    .react-calendar {
      border: #E3EBF6 1px solid;
      width:100%;
      &__tile {
        &--now {
          background-color: #2C7BE580 ;
          &:hover {
            background-color: #2C7BE590 ;
          }
        }
      }
    }
    width:100%;
    &__inputGroup {
      float: left;
      min-width: unset;
      flex-grow: 0;
      &__input {
        border:0;
        outline: 0;
      }
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;
      letter-spacing: -0.24px;
      &__input:invalid {
        background-color: transparent;
      }
    }
    &__wrapper {
      padding-left:7px;
      background-color: #F7F8FA;
      border: #E3EBF6 1px solid;
      padding: 0px 3px 0px 6px;
      height:40px;
    }
    z-index: 100;
    &__calendar {
      width:100%;
      z-index: 100;
    }
    &__clear-button {
      display:none;
    }
    &__calendar-button {
      border:0;
      outline: 0;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  
  .react-date-picker {
    &--open { 
      z-index:1000
    }
    .react-date-picker__calendar--open {
      top: 100% !important;
      bottom: unset !important;
    }
    .react-calendar {
      border: #E3EBF6 1px solid;
      width:100%;
      &__tile {
        &--now {
          background-color: #2C7BE580 ;
          &:hover {
            background-color: #2C7BE590 ;
          }
        }
      }
    }
    width:100%;
    &__inputGroup {
      float: left;
      min-width: unset;
      flex-grow: 0;
      &__input {
        border:0;
        outline: 0;
      }
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;
      letter-spacing: -0.24px;
      &__input:invalid {
        background-color: transparent;
      }
    }
    &__wrapper {
      padding-left:7px;
      background-color: #F7F8FA;
      border: #E3EBF6 1px solid;
      padding: 0px 3px 0px 6px;
      height:40px;
    }
    &.is-invalid {
      .react-date-picker__wrapper {
        border-color: var(--danger);
        background-color: var(--danger-bg);
      }
    }
    z-index: 100;
    &__calendar {
      z-index: 100;
    }
    &__clear-button {
      display:none;
    }
    &__calendar-button {
      border:0;
      outline: 0;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  input::placeholder {
    color:#6E84A3;
  } 
  
  textarea {
    font-family: Raleway;
    font-style: normal;
  } 
  textarea::placeholder {
    font-family: Raleway;
    font-style: normal;
    color:#6E84A3;
  } 
  .invalid-feedback {
    width: auto;
    margin-top: 0.25rem;
    font-size: 80%;
    color: black;
    z-index: 10;
    right:40px;
    bottom:0;
    transform:translateY(calc(100% + 10px));
    position: absolute;
    background-color:#fff;
    padding:12px;
    padding-top:8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    &::before {
      content:"";
      position: absolute;
      top:-5px;
      right:30px;
      height:10px;
      width:10px;
      transform: rotate(45deg);
      border-top-left-radius: 3px;
      background-color: white;
    }
  }
}


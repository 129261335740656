$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

%text-semibold {
  /* PN Text Semibold */
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

%h1 {
  /* PN Title 1 Bold */
  font-weight: 800;
  font-size: 1.6em;
  line-height: 1.1em;
}

%h1-semibold {
  /* PN Title 1 Semibold */
  font-weight: 600;
  font-size: 1.6em;
  line-height: 1.1em;
  letter-spacing: 0.326451px;
}

%h2 {
  font-weight: 800;
  font-size: 0.8em;
  line-height: 1.1em;
}

%h2-semibold {
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.1em;
}

.banner {
  @media (max-width: $lg) {
    min-height: 242px;
  }

  @media (max-width: $md) {
    min-height: 276px;
  }

  @media (max-width: $sm) {
    min-height: 230px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 368px;
  min-width: 100%;
  z-index: 20;
  background-size: cover;
  background-repeat: no-repeat;

  background-color: #210060;

  h1 {
    @media (max-width: $lg) {
      font-size: 1.0em;
    }

    @media (max-width: $md) {
      font-size: 1.0em;
    }

    @media (max-width: $sm) {
      font-size: 0.8em;
    }

    @extend %h1;
    white-space: break-spaces;
    color: white;
    display: block;
    box-sizing: border-box;

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    @media (max-width: $lg) {
      font-size: 0.5em;
    }

    @media (max-width: $md) {
      font-size: 0.5em;
    }

    @media (max-width: $sm) {
      font-size: 0.4em;
    }

    white-space: break-spaces;
    @extend %h2-semibold;
  }

  .banner {
    @media (max-width: $md) {
      margin: 0 20px;
    }

    &__column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 230px;
    }

    &__top {
      @media (max-width: $md) {
        margin-top: var(--main-spacing)
      }

      @media (max-width: $md) {
        margin-top: 14px;
      }

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &__left {
      @media (max-width: $md) {
        width: 100%;
        align-items: start;
      }

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    &__right {
      margin-left: auto;
    }

    &__logo-cms {
      @media (max-width: $lg) {
        height: 85px;
        min-height: 85px;
        max-height: 85px;
        width: 283px;
        min-width: 283px;
      }

      @media (max-width: $md) {
        height: 76px;
      }

      @media (max-width: $sm) {
        height: 65px;
        min-height: 65px;
        max-height: 65px;
        width: 216px;
        min-width: 216px;
      }

      background-size: cover;
      height: 128px;
      width: 426px;
      min-height: 128px;
      min-width: 128px;
      background-repeat: no-repeat;
      text-decoration: none;
    }

    &__logo-minpros {
      @media (max-width: $lg) {
        height: 85px;
        min-height: 85px;
        max-height: 85px;
        width: 85px;
        min-width: 85px;
      }

      @media (max-width: $md) {
        height: 76px;
      }

      @media (max-width: $sm) {
        height: 65px;
        min-height: 65px;
        max-height: 65px;
        width: 65px;
        min-width: 65px;
      }

      background-size: cover;
      height: 128px;
      width: 128px;
      min-height: 128px;
      min-width: 128px;
      background-repeat: no-repeat;
      background-position-x: right;
      text-decoration: none;
    }

    &__title {
      @media (max-width: $lg) {
        white-space: normal;
      }

      @extend %h1;
      color: white;
      display: block;
      box-sizing: border-box;
      margin-left: 24px;

      &:hover {
        text-decoration: none;
      }
    }

    &__title2 {
      @media (max-width: $lg) {
        white-space: normal;
      }

      @extend %h1-semibold;
      font-size: 0.85em;
    }

    &__mintitle {
      @media (max-width: $md) {
        font-size: 0.9em;
      }

      @media (max-width: $sm) {
        font-size: 0.8em;
      }

      color: white;
      font-weight: lighter;
      font-size: 0.95em;
      margin-top: 1em;
      display: block;
      align-self: flex-start;
      box-sizing: border-box;

      &:hover {
        text-decoration: none;
      }
    }

    &__wave {
      @media (max-width: $lg) {
        height: 242px;
      }

      @media (max-width: $md) {
        height: 276px;
      }

      @media (max-width: $sm) {
        height: 230px;
      }

      position: relative;
      height: 368px;
      top: 0;
      left: 0;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  .wave-container {
    @media (max-width: $lg) {
      min-height: 242px;
    }

    @media (max-width: $md) {
      min-height: 276px;
    }

    @media (max-width: $sm) {
      min-height: 230px;
    }

    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    height: auto;
    min-height: 368px;
  }
}